import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { EnviromentZone } from '@howdeniberia/core-front';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.zone === EnviromentZone.PRODUCTION
  || environment.zone === EnviromentZone.PROHOWDENARTAI
  || environment.zone === EnviromentZone.PROHOWDENGROUP
) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
